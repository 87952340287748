import styled from 'styled-components';

export const Styles = styled.div`
  color: var(--cSecondary);
  font-size: var(--h4);
  font-weight: 400;

  .meta {
    h2 {
      color: var(--cTertiary);
      font-size: var(--h4);
      margin: calc(var(--spacing) / 2) 0;
    }

    + p:nth-child(3) {
      margin-top: calc(var(--spacing) * 2);
    }
  }

  .tags {
    a {
      margin-left: calc(var(--spacing) / 2);
    }
  }

  a {
    position: relative;
    text-decoration: none;

    &:after {
      background-color: var(--cSecondary);
      bottom: -.25rem;
      content: "";
      display: block;
      height: .1rem;
      left: 0;
      opacity: 1;
      position: absolute;
      transition: opacity var(--transSlow);
      width: 100%;
    }

    &:hover,
    &:focus {
      cursor: pointer;

      &:after {
        opacity: .15;
      }

      button:after {
        opacity: 0;
      }
    }
  }
`;
