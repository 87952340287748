import { Link } from 'gatsby';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/Button';
import { MDXBody } from '../../components/MDXBody';
import { SEO } from '../../components/SEO';
import { Signature } from '../../components/Signature/Signature';

import { Styles } from './styled';
import { Props } from './types';

export function Post({
  data: {
    mdx: {
      body,
      frontmatter: {
        author,
        date,
        description,
        tags,
        title,
      },
    },
  },
  pageContext: {
    journalPath,
  },
}: Props) {
  const { t } = useTranslation();

  return (
    <Styles>
      <SEO description={description} title={title} />
      <h1>
        {title}
      </h1>
      <aside className="meta">
        <h2>
          {t('template.post.description', {
            author,
            date,
          })}
        </h2>
      </aside>
      <MDXBody>
        {body}
      </MDXBody>
      <Signature author={author} />
      <hr />
      <Link className="btn-link" to={journalPath}>
        <Button>
          {t('template.post.button.back')}
        </Button>
      </Link>
      {tags && (
        <>
          <hr />
          <h4 className="tags">
            {t('template.post.more')}
            {tags.sort().map((item, index) => (
              <Fragment key={index}>
                <Link to={`/explore/${item}`}>
                  {item}
                </Link>
                {index < tags.length - 1 && ','}
              </Fragment>
            ))}
          </h4>
        </>
      )}
    </Styles>
  );
}
